import React from 'react'
import Layout from "@layout/layout"
import {withPrefix} from 'gatsby'
import TableOfContents from '../components/delivering-school/table-of-contents'
import SEO from "@layout/seo";
import Helmet from "react-helmet";

export default () => {

  return (
    <Layout>
      <SEO
        title={'Szkoła rodzenia w Warszawie - Poradnia ginekologiczna Tiliamed'}
        description={'Poradnia ginekologiczna Tiliamed z Warszawy skutecznie przygotowuje do porodu jak i do opieki nad dzieckiem. Przekazujemy naszą widzę abyś czuła się bezpiecznie.'}
      />
      <div className="content">
        <main>
          <div className="school">
            <section className="page-margin">
              <div className="school__hero" style={{
                background: `url(${withPrefix('images/school-hero.jpg')}) 50% 50%/cover no-repeat`
              }}>
                <h1 className="school__content__heading visible-xs">
                  Szkoła rodzenia </h1>
              </div>
              <div className="school__content">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-7">
                      <h1 className="school__content__heading hidden-xs">
                        Szkoła rodzenia </h1>
                      <div className="school__content__text">
                        <p/>
                        <div id="WEBzona" style={{display: 'none'}}>
                          <h2><strong>Szkoła rodzenia Perełka przy współpracy z Tiliamed w Wołominie.</strong></h2>
                          <p>W celu dobrego przygotowania się do ciąży i porodu zalecamy odwiedzić Szkołę Rodzenia
                            Perełka. Tiliamed. Tematy poruszane w szkole rodzenia to m.in:</p>
                          <table>
                            <tbody>
                            <tr>
                              <td>
                                <h3><strong>przygotowania do ciąży Wołomin</strong></h3>
                              </td>
                            </tr>
                            <tr>
                              <td><strong>poród po terminie</strong></td>
                            </tr>
                            <tr>
                              <td>
                                <h3><strong>przedwczesny poród</strong></h3>
                              </td>
                            </tr>
                            <tr>
                              <td><strong>co przed porodem</strong></td>
                            </tr>
                            <tr>
                              <td>
                                <h3><strong>plan porodu Wołomin</strong></h3>
                              </td>
                            </tr>
                            <tr>
                              <td><strong>ćwiczenia w czasie ciąży</strong></td>
                            </tr>
                            <tr>
                              <td>
                                <h3><strong>Położna Warszawa / Wołomin.</strong></h3>
                              </td>
                            </tr>
                            <tr>
                              <td><strong>Ćwiczenia w czasie ciąży i wiele innego…</strong></td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <p><span style={{fontWeight: 400}}><strong>Szkoła rodzenia</strong> to doskonały sposób, aby przygotować się zarówno do porodu, jak i do opieki nad nowym członkiem rodziny. Wieloletnia współpraca ze <strong>Szkołą Rodzenia Perełka w Wołominie</strong> pozwala nam z pełnym przekonaniem polecać to miejsce naszym pacjentkom. Zajęcia w tym ośrodku są prowadzone przez wykwalifikowaną kadrę kierowaną przez <strong>mgr poł. Małgorzatę Olszewską</strong>. </span>
                        </p>
                        <p><span style={{fontWeight: 400}}>Kurs prowadzony w Szkole Rodzenia Perełka składa się z dwóch części – praktycznej i teoretycznej. Stanowią one kompletny zakres wiedzy dla młodych rodziców dotyczący macierzyństwa. </span>
                        </p>
                        <h3><span style={{fontSize: '15pt'}}><strong><span style={{fontWeight: 400}}><em>Szkoła Rodzenia:</em> </span>Część teoretyczna</strong></span>
                        </h3>
                        <p><span style={{fontWeight: 400}}>Przyszli rodzice otrzymają tu niezbędne informacje na temat przebiegu ciąży, porodu i połogu. Mając odpowiednią wiedzę na temat wymienionych zagadnień, rodzice lepiej znoszą związane z nimi sytuacje, ponieważ są do nich odpowiednio przygotowani. Istotnym elementem kursu jest część dotycząca opieki nad noworodkiem, a także sprawy formalne związane z rejestracją narodzin nowego członka rodziny.</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <div className="school__content__price hidden-xs">
                        <p>Cena za serię spotkań:</p>
                        <p className="color-pink">500 zł</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="school__gallery">
                <div className="visible-xs swipe">
                  przesuń w prawo<br/>
                  <svg width={43} height={16} viewBox="0 0 43 16" xmlns="http://www.w3.org/2000/svg"><title>czytaj dalej
                    arrow</title>
                    <path
                      d="M42.584 6.803c-.015-.015-.01-.04-.025-.056L36.392.371a1.207 1.207 0 0 0-1.739 0 1.3 1.3 0 0 0 0 1.797l4.135 4.275H1.228C.55 6.443 0 7.01 0 7.712c0 .7.55 1.27 1.228 1.27h37.555l-4.13 4.269a1.293 1.293 0 0 0 0 1.792c.241.249.555.376.87.376.314 0 .628-.127.869-.376l6.167-6.377c.256-.274.408-.594.408-.954s-.152-.675-.383-.909z"
                      fill="#9B9B9B" fillRule="evenodd"/>
                  </svg>
                </div>
                <div className="owl-carousel">
                  <div className="image">
                    <img src="/wp-uploads/2017/01/zdjecie_6-768x576.jpg"
                         alt="szkoła rodzenia wołomin"/>
                  </div>
                  <div className="image">
                    <img src="/wp-uploads/2017/01/3sdp4zc_z9w-arteida-mjeshtri-768x432.jpg"
                         alt="ciąża wołomin"/>
                  </div>
                  <div className="image">
                    <img src="/wp-uploads/2017/01/zdjecie_8-768x576.jpg"
                         alt="szkoła rodzenia"/>
                  </div>
                  <div className="image">
                    <img src="/wp-uploads/2017/01/ztlasjerpb0-valeria-zoncoll-768x512.jpg"
                         alt="mały superman tiliamed"/>
                  </div>
                  <div className="image">
                    <img src="/wp-uploads/2017/01/zdjecie_11-768x576.jpg"
                         alt="szkoła rodzenia"/>
                  </div>
                  <div className="image">
                    <img src="/wp-uploads/2017/01/zdjecie_4-768x576.jpg"
                         alt="szkoła rodzenia"/>
                  </div>
                  <div className="image">
                    <img src="/wp-uploads/2017/01/zdjecie_10-768x576.jpg"
                         alt="szkoła rodzenia"/>
                  </div>
                  <div className="image">
                    <img src="/wp-uploads/2017/01/zdjecie_2-768x576.jpg"
                         alt="ginekologia wołomin"/>
                  </div>
                  <div className="image">
                    <img src="/wp-uploads/2017/01/uxiu_t202oy-andrew-branch-768x512.jpg"
                         alt="dziecko wołomin"/>
                  </div>
                  <div className="image">
                    <img src="/wp-uploads/2017/01/zdjecie_3-768x576.jpg"
                         alt="klinika wołomin"/>
                  </div>
                </div>
              </div>
              <div className="school__content school__content--second">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-7">
                      <div className="school__content__text">
                        <h3><span style={{fontSize: '15pt'}}><strong><span style={{fontWeight: 400}}><em>Szkoła Rodzenia:</em> </span></strong></span><span
                          style={{fontSize: '15pt'}}><strong>Część praktyczna </strong></span></h3>
                        <p>Dotyczy nauki prawidłowych zachowań podczas porodu, łagodzenia bólu porodowego, a także cykl
                          ćwiczeń ogólnorozwojowych. Chcąc jak najlepiej przygotować przyszłych rodziców do porodu, plan
                          kursu zakłada zwiedzanie oddziału położniczego w Wołominie oraz pakowanie walizki
                          szpitalnej.</p>
                        <h3><span style={{fontSize: '15pt'}}><strong>Szkoła rodzenia dla kobiet ze wskazaniami do cesarskiego cięcia.</strong></span>
                        </h3>
                        <p>Wychodząc naprzeciw współczesnym metodom rozwiązania, w ofercie <strong>Szkoły Rodzenia
                          Perełka w Wołominie</strong> znalazły się także warsztaty dla kobiet mających wskazania do
                          przeprowadzenia porodu metodą cesarskiego cięcia.</p>
                        <p>Szczegółowe informacje związane z terminami, tematyką i cennikiem spotkań są dostępne pod
                          adresem: <a href="http://perelka.waw.pl/" target="_blank"
                                      rel="nofollow noopener">http://perelka.waw.pl</a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container visible-xs">
                <div className="row">
                  <div className="col-xs-12">
                    <div className="school__content__price">
                      <p>Cena za serię spotkań:</p>
                      <p className="color-pink">500 zł</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="home__testimonials testimonials">
              <div className="container">
                <div className="home__testimonials__carousel js-home-testimonials slick-slider">
                  <div>
                    <div className="home__testimonials__carousel__item">
                      <div>
                        <div className="home__testimonials__carousel__item__content">
                          <p>Jesteśmy bardzo zadowoleni z profesjonalnego przygotowania do porodu, oraz opieki w trakcie
                            i po porodzie.<br/>
                            Małgosia to fantastyczna osoba – nie znam drugiej takiej – pełna zapału oraz poświęcenia dla
                            innych.<br/>
                            Jesteśmy wdzięczni za wszelką pomoc.</p>
                          <p>Serdecznie pozdrawiam i życzymy samych sukcesów i takiej radości jak dotychczas.</p>
                        </div>
                        <p className="home__testimonials__carousel__item__author">
                          ~ Katarzyna, Michał oraz Stach Kotkiewicz </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="home__testimonials__carousel__item">
                      <div>
                        <div className="home__testimonials__carousel__item__content">
                          <p>Szkoła Rodzenia „Perełka” to wyjątkowe spotkania, niesamowita pomoc i cudowna położna:
                            Małgosia. Polecamy, bo sami przetestowaliśmy. Takich spotkań i ludzi nie zapomina się nigdy.
                            Małgosiu dziękujemy…</p>
                        </div>
                        <p className="home__testimonials__carousel__item__author">
                          ~ Katarzyna I Rafał Oraz Nasze Córeczki </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="home__testimonials__carousel__item">
                      <div>
                        <div className="home__testimonials__carousel__item__content">
                          <p>Bardzo dziękujemy za fachowe przygotowanie nas do porodu oraz udzielanie niezbędnych
                            informacji i rad w każdym momencie gdy zachodziła taka potrzeba.</p>
                          <p>Czuliśmy się bezpieczniej podczas porodu. Mając większą wiedzę dotyczącą opieki nad
                            noworodkiem łatwiej nam jest zajmować się naszą córeczką.<br/>
                            Zajęcia w Szkole Rodzenia są prowadzone w sposób profesjonalny, ale przy tym ciekawy. Bardzo
                            mile wspominamy ten czas i polecamy wszystkim parom spodziewającym się Maleństwa.</p>
                        </div>
                        <p className="home__testimonials__carousel__item__author">
                          ~ Ania, Bartosz z Dominiką </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
      <TableOfContents />
    </Layout>
  )
}
