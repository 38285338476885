import React from 'react'

export default () => (
  <div>
    <div>
      <p />
      <div id="WEBzona" style={{display: 'none'}}>
        <h2><strong>Szkoła rodzenia Perełka przy współpracy z Tiliamed w Wołominie.</strong></h2>
        <p>W celu dobrego przygotowania się do ciąży i porodu zalecamy odwiedzić Szkołę Rodzenia Perełka. Tiliamed. Tematy poruszane w szkole rodzenia to m.in:</p>
        <table>
          <tbody>
          <tr>
            <td>
              <h3><strong>przygotowania do ciąży Wołomin</strong></h3>
            </td>
          </tr>
          <tr>
            <td><strong>poród po terminie</strong></td>
          </tr>
          <tr>
            <td>
              <h3><strong>przedwczesny poród</strong></h3>
            </td>
          </tr>
          <tr>
            <td><strong>co przed porodem</strong></td>
          </tr>
          <tr>
            <td>
              <h3><strong>plan porodu Wołomin</strong></h3>
            </td>
          </tr>
          <tr>
            <td><strong>ćwiczenia w czasie ciąży</strong></td>
          </tr>
          <tr>
            <td>
              <h3><strong>Położna Warszawa / Wołomin.</strong></h3>
            </td>
          </tr>
          <tr>
            <td><strong>Ćwiczenia w czasie ciąży i wiele innego…</strong></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
)
